(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbCountry')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'country.AFG': 'Afganiszt\u00e1n',
      'country.ALA': '\u00c5land',
      'country.ALB': 'Alb\u00e1nia',
      'country.DZA': 'Alg\u00e9ria',
      'country.UMI': 'Amerikai Csendes-\u00f3ce\u00e1ni szigetek',
      'country.USA': 'Amerikai Egyes\u00fclt \u00c1llamok',
      'country.ASM': 'Amerikai Szamoa',
      'country.VIR': 'Amerikai Virgin-szigetek',
      'country.AND': 'Andorra',
      'country.AGO': 'Angola',
      'country.AIA': 'Anguilla',
      'country.ATA': 'Antarktisz',
      'country.ATG': 'Antigua \u00e9s Barbuda',
      'country.ARG': 'Argent\u00edna',
      'country.ABW': 'Aruba',
      'country.AUS': 'Ausztr\u00e1lia',
      'country.AUT': 'Ausztria',
      'country.AZE': 'Azerbajdzs\u00e1n',
      'country.BHS': 'Bahama-szigetek',
      'country.BHR': 'Bahrein',
      'country.BGD': 'Banglades',
      'country.BRB': 'Barbados',
      'country.BEL': 'Belgium',
      'country.BLZ': 'Belize',
      'country.BEN': 'Benin',
      'country.BMU': 'Bermuda',
      'country.BTN': 'Bhut\u00e1n',
      'country.GNB': 'Bissau-Guinea',
      'country.BOL': 'Bol\u00edvia',
      'country.BIH': 'Bosznia-Hercegovina',
      'country.BWA': 'Botswana',
      'country.BVT': 'Bouvet-sziget',
      'country.BRA': 'Braz\u00edlia',
      'country.IOT': 'Brit Indiai-\u00f3ce\u00e1ni Ter\u00fclet',
      'country.VGB': 'Brit Virgin-szigetek',
      'country.BRN': 'Brunei',
      'country.BGR': 'Bulg\u00e1ria',
      'country.BFA': 'Burkina Faso',
      'country.BDI': 'Burundi',
      'country.CHL': 'Chile',
      'country.CYP': 'Ciprus',
      'country.COM': 'Comore-szigetek',
      'country.COK': 'Cook-szigetek',
      'country.CRI': 'Costa Rica',
      'country.CUW': 'Cura\u00e7ao',
      'country.TCD': 'Cs\u00e1d',
      'country.CZE': 'Csehorsz\u00e1g',
      'country.DNK': 'D\u00e1nia',
      'country.ZAF': 'D\u00e9l-afrikai K\u00f6zt\u00e1rsas\u00e1g',
      'country.SGS': 'D\u00e9li-Georgia \u00e9s D\u00e9li-Sandwich-szigetek',
      'country.SSD': 'D\u00e9l-Szud\u00e1n',
      'country.KOR': 'Koreai K\u00f6zt\u00e1rsas\u00e1g',
      'country.DMA': 'Dominikai K\u00f6z\u00f6ss\u00e9g',
      'country.DOM': 'Dominikai K\u00f6zt\u00e1rsas\u00e1g',
      'country.DJI': 'Dzsibuti',
      'country.ECU': 'Ecuador',
      'country.GNQ': 'Egyenl\u00edt\u0151i-Guinea',
      'country.ARE': 'Egyes\u00fclt Arab Em\u00edrs\u00e9gek',
      'country.GBR': 'Egyes\u00fclt Kir\u00e1lys\u00e1g',
      'country.EGY': 'Egyiptom',
      'country.CIV': 'Elef\u00e1ntcsontpart',
      'country.SLV': 'El Salvador',
      'country.ERI': 'Eritrea',
      'country.MNP': '\u00c9szaki-Mariana-szigetek',
      'country.PRK': 'Koreai N\u00e9pi Demokratikus K\u00f6zt\u00e1rsas\u00e1g',
      'country.EST': '\u00c9sztorsz\u00e1g',
      'country.ETH': 'Eti\u00f3pia',
      'country.FLK': 'Falkland-szigetek',
      'country.BLR': 'Feh\u00e9roroszorsz\u00e1g',
      'country.FRO': 'Fer\u00f6er',
      'country.FJI': 'Fidzsi',
      'country.FIN': 'Finnorsz\u00e1g',
      'country.ATF': 'Francia d\u00e9li \u00e9s antarktiszi ter\u00fcletek',
      'country.GUF': 'Francia Guyana',
      'country.FRA': 'Franciaorsz\u00e1g',
      'country.PYF': 'Francia Polin\u00e9zia',
      'country.PHL': 'F\u00fcl\u00f6p-szigetek',
      'country.GAB': 'Gabon',
      'country.GMB': 'Gambia',
      'country.GHA': 'Gh\u00e1na',
      'country.GIB': 'Gibralt\u00e1r',
      'country.GRC': 'G\u00f6r\u00f6gorsz\u00e1g',
      'country.GRD': 'Grenada',
      'country.GRL': 'Gr\u00f6nland',
      'country.GEO': 'Gr\u00fazia',
      'country.GLP': 'Guadeloupe',
      'country.GUM': 'Guam',
      'country.GTM': 'Guatemala',
      'country.GGY': 'Guernsey',
      'country.GIN': 'Guinea',
      'country.GUY': 'Guyana',
      'country.HTI': 'Haiti',
      'country.HMD': 'Heard-sziget \u00e9s McDonald-szigetek',
      'country.NLD': 'Hollandia',
      'country.HND': 'Honduras',
      'country.HKG': 'Hongkong',
      'country.HRV': 'Horv\u00e1torsz\u00e1g',
      'country.IND': 'India',
      'country.IDN': 'Indon\u00e9zia',
      'country.IRQ': 'Irak',
      'country.IRN': 'Ir\u00e1n',
      'country.IRL': '\u00cdrorsz\u00e1g',
      'country.ISL': 'Izland',
      'country.ISR': 'Izrael',
      'country.JAM': 'Jamaica',
      'country.JPN': 'Jap\u00e1n',
      'country.YEM': 'Jemen',
      'country.JEY': 'Jersey',
      'country.JOR': 'Jord\u00e1nia',
      'country.CYM': 'Kajm\u00e1n-szigetek',
      'country.KHM': 'Kambodzsa',
      'country.CMR': 'Kamerun',
      'country.CAN': 'Kanada',
      'country.CXR': 'Kar\u00e1csony-sziget',
      'country.BES': 'Karibi Hollandia',
      'country.QAT': 'Katar',
      'country.KAZ': 'Kazahszt\u00e1n',
      'country.TLS': 'Kelet-Timor',
      'country.KEN': 'Kenya',
      'country.CHN': 'K\u00edna',
      'country.KGZ': 'Kirgiziszt\u00e1n',
      'country.KIR': 'Kiribati',
      'country.CCK': 'K\u00f3kusz (Keeling)-szigetek',
      'country.COL': 'Kolumbia',
      'country.COD': 'Kong\u00f3i Demokratikus K\u00f6zt\u00e1rsas\u00e1g',
      'country.COG': 'Kong\u00f3i K\u00f6zt\u00e1rsas\u00e1g',
      'country.XKX': 'Koszov\u00f3',
      'country.CAF': 'K\u00f6z\u00e9p-Afrika',
      'country.CUB': 'Kuba',
      'country.KWT': 'Kuvait',
      'country.LAO': 'Laosz',
      'country.POL': 'Lengyelorsz\u00e1g',
      'country.LSO': 'Lesotho',
      'country.LVA': 'Lettorsz\u00e1g',
      'country.LBN': 'Libanon',
      'country.LBR': 'Lib\u00e9ria',
      'country.LBY': 'L\u00edbia',
      'country.LIE': 'Liechtenstein',
      'country.LTU': 'Litv\u00e1nia',
      'country.LUX': 'Luxemburg',
      'country.MKD': 'Maced\u00f3nia',
      'country.MDG': 'Madagaszk\u00e1r',
      'country.HUN': 'Magyarorsz\u00e1g',
      'country.MAC': 'Maka\u00f3',
      'country.MYS': 'Malajzia',
      'country.MWI': 'Malawi',
      'country.MDV': 'Mald\u00edv-szigetek',
      'country.MLI': 'Mali',
      'country.MLT': 'M\u00e1lta',
      'country.IMN': 'Man',
      'country.MAR': 'Marokk\u00f3',
      'country.MHL': 'Marshall-szigetek',
      'country.MTQ': 'Martinique',
      'country.MRT': 'Maurit\u00e1nia',
      'country.MUS': 'Mauritius',
      'country.MYT': 'Mayotte',
      'country.MEX': 'Mexik\u00f3',
      'country.MMR': 'Mianmar',
      'country.FSM': 'Mikron\u00e9zia',
      'country.MDA': 'Moldova',
      'country.MCO': 'Monaco',
      'country.MNG': 'Mong\u00f3lia',
      'country.MNE': 'Montenegr\u00f3',
      'country.MSR': 'Montserrat',
      'country.MOZ': 'Mozambik',
      'country.NAM': 'Nam\u00edbia',
      'country.NRU': 'Nauru',
      'country.DEU': 'N\u00e9metorsz\u00e1g',
      'country.NPL': 'Nep\u00e1l',
      'country.NIC': 'Nicaragua',
      'country.NER': 'Niger',
      'country.NGA': 'Nig\u00e9ria',
      'country.NIU': 'Niue',
      'country.NFK': 'Norfolk-sziget',
      'country.NOR': 'Norv\u00e9gia',
      'country.ESH': 'Nyugat-Szahara',
      'country.ITA': 'Olaszorsz\u00e1g',
      'country.OMN': 'Om\u00e1n',
      'country.RUS': 'Oroszorsz\u00e1g',
      'country.ARM': '\u00d6rm\u00e9nyorsz\u00e1g',
      'country.PAK': 'Pakiszt\u00e1n',
      'country.PLW': 'Palau',
      'country.PSE': 'Palesztina',
      'country.PAN': 'Panama',
      'country.PNG': 'P\u00e1pua \u00daj-Guinea',
      'country.PRY': 'Paraguay',
      'country.PER': 'Peru',
      'country.PCN': 'Pitcairn-szigetek',
      'country.PRT': 'Portug\u00e1lia',
      'country.PRI': 'Puerto Rico',
      'country.REU': 'R\u00e9union',
      'country.ROU': 'Rom\u00e1nia',
      'country.RWA': 'Ruanda',
      'country.BLM': 'Saint Barthelemy',
      'country.KNA': 'Saint Kitts \u00e9s Nevis',
      'country.LCA': 'Saint Lucia',
      'country.MAF': 'Saint Martin',
      'country.SPM': 'Saint-Pierre \u00e9s Miquelon',
      'country.VCT': 'Saint Vincent \u00e9s a Grenadine-szigetek',
      'country.SLB': 'Salamon-szigetek',
      'country.SMR': 'San Marino',
      'country.STP': 'S\u00e3o Tom\u00e9 \u00e9s Pr\u00edncipe',
      'country.SYC': 'Seychelle-szigetek',
      'country.SLE': 'Sierra Leone',
      'country.SXM': 'Sint Maarten',
      'country.ESP': 'Spanyolorsz\u00e1g',
      'country.SJM': 'Spitzberg\u00e1k \u00e9s Jan Mayen-sziget',
      'country.LKA': 'Sr\u00ed Lanka',
      'country.SUR': 'Suriname',
      'country.CHE': 'Sv\u00e1jc',
      'country.SWE': 'Sv\u00e9dorsz\u00e1g',
      'country.WSM': 'Szamoa',
      'country.SAU': 'Sza\u00fad-Ar\u00e1bia',
      'country.SEN': 'Szeneg\u00e1l',
      'country.SHN': 'Szent Ilona',
      'country.SRB': 'Szerbia',
      'country.SCG': 'Szerbia \u00e9s Montenegr\u00f3',
      'country.SGP': 'Szingap\u00far',
      'country.SYR': 'Sz\u00edria',
      'country.SVK': 'Szlov\u00e1kia',
      'country.SVN': 'Szlov\u00e9nia',
      'country.SOM': 'Szom\u00e1lia',
      'country.SDN': 'Szud\u00e1n',
      'country.SWZ': 'Szv\u00e1zif\u00f6ld',
      'country.TJK': 'T\u00e1dzsikiszt\u00e1n',
      'country.TWN': 'Tajvan',
      'country.TZA': 'Tanz\u00e1nia',
      'country.THA': 'Thaif\u00f6ld',
      'country.TGO': 'Togo',
      'country.TKL': 'Tokelau',
      'country.TON': 'Tonga',
      'country.TUR': 'T\u00f6r\u00f6korsz\u00e1g',
      'country.TTO': 'Trinidad \u00e9s Tobago',
      'country.TUN': 'Tun\u00e9zia',
      'country.TCA': 'Turks- \u00e9s Caicos-szigetek',
      'country.TUV': 'Tuvalu',
      'country.TKM': 'T\u00fcrkmeniszt\u00e1n',
      'country.UGA': 'Uganda',
      'country.NCL': '\u00daj-Kaled\u00f3nia',
      'country.NZL': '\u00daj-Z\u00e9land',
      'country.UKR': 'Ukrajna',
      'country.URY': 'Uruguay',
      'country.UZB': '\u00dczbegiszt\u00e1n',
      'country.VUT': 'Vanuatu',
      'country.VAT': 'Vatik\u00e1n',
      'country.VEN': 'Venezuela',
      'country.VNM': 'Vietnam',
      'country.WLF': 'Wallis \u00e9s Futuna',
      'country.ZMB': 'Zambia',
      'country.ZWE': 'Zimbabwe',
      'country.CPV': 'Z\u00f6ld-foki-szigetek'
    });
  }
}());
